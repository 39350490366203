import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the word in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </p>
	  
	  
	<p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        Beware, there is a mole in our midst. The information in one column is a lie.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="F"
          status="correct"
        />
        <Cell value="I" />
        <Cell value="R" />
        <Cell value="S" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter F is in the word and in the correct spot, unless the letter F came from the Mole.
      </p>
	  
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="I" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="L"
          status="present"
        />
        <Cell value="O" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter L is in the word but in the wrong spot, unless the letter L came from the Mole.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="V" />
        <Cell value="A" />
        <Cell value="G" />
        <Cell isRevealing={true} isCompleted={true} value="U" status="absent" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter U is not in the word in any spot, unless the letter U came from the Mole.
      </p>

	  <p className="text-sm text-gray-500 dark:text-gray-300">
        The Mole will always lie.
		The Mole will respond with one of the two other states at random for each guess. 
      </p>
	  
	  <p className="text-sm text-gray-500 dark:text-gray-300">
        In the first example, the mole would return:
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="F"
          status="present"
        />
        <Cell value="I" />
        <Cell value="R" />
        <Cell value="S" />
        <Cell value="T" />
      </div>
	  	  <p className="text-sm text-gray-500 dark:text-gray-300">
        or
      </p>
	  
	        <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="F"
          status="absent"
        />
        <Cell value="I" />
        <Cell value="R" />
        <Cell value="S" />
        <Cell value="T" />
      </div>
      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
          Built using reactle github
        </a>{' '}
      </p>
    </BaseModal>
  )
}
