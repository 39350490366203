import { solution, mole, moled, unicodeSplit, solutionIndex } from './words'

export type CharStatus = 'absent' | 'present' | 'correct'

export const getStatuses = (
  guesses: string[]
): { [key: string]: CharStatus } => {
  const charObj: { [key: string]: CharStatus } = {}
  const splitSolution = unicodeSplit(solution)

  guesses.forEach((word) => {
    unicodeSplit(word).forEach((letter, i) => {
      if (!splitSolution.includes(letter)) {
        // make status absent
        return (charObj[letter] = 'absent')
      }

      if (letter === splitSolution[i]) {
        //make status correct
        return (charObj[letter] = 'absent')
      }

      if (charObj[letter] !== 'correct') {
        //make status present
        return (charObj[letter] = 'absent')
      }
    })
  })

  return charObj
}

export const getGuessStatuses = (guess: string, line: number): CharStatus[] => {
  const splitSolution = unicodeSplit(solution)
  const splitGuess = unicodeSplit(guess)

  const solutionCharsTaken = splitSolution.map((_) => false)

  const statuses: CharStatus[] = Array.from(Array(guess.length))

  // handle all correct cases first
  
 if(splitGuess[0] === splitSolution[0] && splitGuess[1] === splitSolution[1] && splitGuess[2] === splitSolution[2] && splitGuess[3] === splitSolution[3] && splitGuess[4] === splitSolution[4])
 {
   splitGuess.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      statuses[i] = 'correct'
      solutionCharsTaken[i] = true
      return
    }
  })
 }
 else
 {

  splitGuess.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      statuses[i] = 'correct'
      solutionCharsTaken[i] = true
	 if(i === mole && line !== 5)
	 {
	
		if(moled[line][solutionIndex] === 1)
		{
			statuses[i] = 'present'
			solutionCharsTaken[i] = true
		}
		else
		{
			statuses[i] = 'absent'
	        solutionCharsTaken[i] = false
		}
	  }
      return
    }
  })
  
  }

  splitGuess.forEach((letter, i) => {

    if (statuses[i]) return

    if (!splitSolution.includes(letter)) {
      // handles the absent case
      statuses[i] = 'absent'
	 if(i === mole && line !== 5)
	 {
	
		if(moled[line][solutionIndex] === 1)
		{
			statuses[i] = 'present'
		}
		else
		{
			statuses[i] = 'correct'
		}
	  }
      return
    }

    // now we are left with "present"s
    const indexOfPresentChar = splitSolution.findIndex(
      (x, index) => x === letter && !solutionCharsTaken[index]
    )

    if (indexOfPresentChar > -1) {
      statuses[i] = 'present'  
      solutionCharsTaken[indexOfPresentChar] = true
	  if(i === mole && line !== 5)
	 {
	
		if(moled[line][solutionIndex] === 1)
		{
			statuses[i] = 'absent'
		}
		else
		{
			statuses[i] = 'correct'
		}
	  }
	  
	  
      return
    } else {
      statuses[i] = 'absent'
	  if(i === mole && line !== 5)
		{
	
		if(moled[line][solutionIndex] === 1)
		{
			statuses[i] = 'present'
		}
		else
		{
			statuses[i] = 'correct'
		}
	  }
      return
    }
  })


  return statuses
}
